import React, { useState , useEffect } from 'react';
import { Grid,Box,Modal } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import passdepgimg from '../../assets/images/Rectangle 13238.png';
import './style.scss';   
import { faTimes} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {getGolfCourseBooking } from '../../Redux/actions/bookingActions'
import { COUPON_VERIFY_FAIL, COUPON_VERIFY_SUCCESS, RESET_ERROR } from '../../Redux/constants/bookingConstants';
import BookingForm from '../Home/BookingForm'
import moment from 'moment';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Noimage from "../../assets/images/no-img.png";

const CourseEnquiryForm = () => {
    
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
   
    const { user,isAuthenticated } = useSelector(state => state.auth);
    const {courseBookingInputs,coureBookingSuccess,selectedCourse } =useSelector(state => state.booking)
    const [courseDetails, setcourseDetails] = useState(courseBookingInputs);
    const [success, setSuccess] = useState(coureBookingSuccess);
    const [full_name, setLeadName] = useState("");
    const [lead_nationality, setleadNationality] = useState("");
    const [lead_handicap, setLeadHandiCap] = useState("0");
    const [contact_name, setcontactName] = useState("");
    const [contact_no, setcontactNo] = useState("");
    const [email, setEmail] = useState("");
    const [captcha, setCaptcha] = useState("");
    const [contact_nationality, setcontactNationality] = useState("");
    const [remarks, setRemarks] = useState("");
    const [acceptSubmit, setacceptSubmit] = useState(false);
    const [emailBooking, setemailBooking] = useState(false);
    const [mesagingApp, setmesagingApp] = useState(false);
    const [modelOpen, setModelOpen] = useState(false);
    const [nationalities , setNationalities] = useState([]);
    const [inerrors, setErrors] = useState(
        {
        full_name:false,
        email:false,
        lead_nationality:false,
        lead_handicap:false,
        contact_name:false,
        contact_no:false,
        contact_nationality:false,
        remark:false});


        const {userCurrencyCode , currencyRate , dbCountry} = useSelector(state => state.currency);
        const [currencyCode , setCurrencyCode] = useState('')
        const [currencyRateList , setCurrencyRateList] = useState([]);
      
        useEffect(() => {
          if(userCurrencyCode){
              setCurrencyCode(userCurrencyCode)
      }
        }, [userCurrencyCode]);
      
        useEffect(() => {
            console.log("user",user) 
          if(currencyRate){
            setCurrencyRateList(currencyRate)
      }
        }, [currencyRate]);

        useEffect(() => {
            if(dbCountry){
                console.log("dbCountry",dbCountry)
                setNationalities(dbCountry)
        }
          }, [dbCountry]);

        const fnctionConvertingPriceWithCurrency = (price , currency) =>{  
            const currencyRate = currencyRateList[currency];
            const convertedPrice =  price / currencyRate
            return convertedPrice.toFixed(2);
        }

    useEffect(() => {
    if( coureBookingSuccess !==undefined){
        setSuccess(coureBookingSuccess)
       }
    if (coureBookingSuccess) {
        //localStorage.removeItem("booking");
        navigation('/SuccessPage');
         dispatch({
                type: RESET_ERROR
            })
    }
    loadCaptchaEnginge(6);
   
    window.scrollTo(0, 0)
   }, [coureBookingSuccess])
   useEffect(()=>{
    if(user){
        // alert(user?.country_code)
        setLeadName(user?.name)
        setLeadPhoneNumber(user?.code + user?.mobile)
        setEmail(user?.email)
        setleadNationality(user?.country_code)
    }
   },[])
 
   const checkConformation = () => {
    setacceptSubmit(!acceptSubmit)
  }
  const IsEmailChecked = () => {
    setemailBooking(!emailBooking)
  }
  const IsMessaging = () => {
    setmesagingApp(!mesagingApp)
  }
  function handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    setFormInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    setErrors((prevState) => {
        return {
          ...prevState,
          [name]: false,
        };
      });
  }
  const [state, setFormInputs] = useState({});      
  const [showerror, setShowError] = useState(null);      
                                           
    const getFormattedPhone = (number) => {
        const phoneNumberObj = parsePhoneNumberFromString(`+${number}`);
        
        if (phoneNumberObj) {
            const countryCode = phoneNumberObj.countryCallingCode;
            const mobileNumber = phoneNumberObj.nationalNumber;
            return `+${countryCode} ${mobileNumber}`;
        } else {
            return number;
        }
    };
   
    const PaymentPage = async(e) => {
        e.preventDefault();
        var c=captcha;
        if(full_name === '' || lead_nationality ==='' || lead_handicap ==='' || contact_name === '' || phoneNumber ==='' || contact_nationality ===''){
            setShowError(true)
            return
           }
        if(!acceptSubmit){
            alert("Please Make Confrmation");
        }else{
           
                if (validateCaptcha(captcha)==false) {
                    alert('Captcha Does Not Match');
                }else {     
            var bookingInput=[];
            var data =courseDetails?.map((price) =>{
                var numbers = price?.startTime.replace(currencyCode, '')?.replace('<span>', '');
                var times = numbers.split(' - ');
                var startTime = times[0]; 
                var endTime = times[1]; 
                return{ 
                golf_course_id:price?.course_id,
                customer_id:user?.id || 0,
                email:email,
                supplier_id:price.supplier_id,
                golf_course_name:price?.course_name,
                golf_course_price:price?.selectedPrice,
                date:price?.startDate,
                start_time:startTime,
                end_time:endTime,
                tee_time:price?.startTime,
                total_price:grandTotal(),
                golf_price:price?.selectedGolfers * price?.selectedPrice,
                convenience_fee:parseInt(calculatePercentage(price.course_id)),
                total_qolfers:price?.selectedGolfers,
                additional_charges:price?.additional_charges,
                lead_golfer_name:full_name,
                lead_golfer_nationality:lead_nationality,
                lead_golfer_handicap:lead_handicap,
                lead_contact_no:getFormattedPhone(leadphoneNumber),
                customer_name:contact_name,
                customer_phone:getFormattedPhone(phoneNumber),
                customer_nationality:contact_nationality,
                customer_remarks:remarks,
                emailBooking:emailBooking,
                mesagingApp:mesagingApp,
                image:price?.image_path,
                promotions:"",
                currency:currencyCode
                }
            })
                bookingInput.push(data)
                var data=bookingInput[0]
                var postData={data}; 
                await dispatch(getGolfCourseBooking(postData));
                for(let each of courseDetails) {
                    if(each?.isCart){
                        localStorage.removeItem('cartData');
                    }
                 }
        } 
    }
    };
   
   
    const [addToCart, setAddtoCart] = useState([]);
    const [isCarted, setisCarted] = useState(false);
    const AddToCart = async(e) => {
        var cartitems = [];
        let course_infos = {
            course_id: selectedCourse?.golf_courses?.id,
            course_name: selectedCourse?.golf_courses?.golf_course_name,
            holes: selectedCourse?.golf_courses?.number_of_holes,
            additional_services: selectedCourse?.golf_courses?.additional_charges,
            image_path: selectedCourse?.golf_courses?.thumb_nail_images,
            created_at: selectedCourse?.golf_courses?.created_at,
            price_information: selectedCourse?.rate_sheets?.price_information,
            location: selectedCourse?.golf_courses?.state_id + ',' + selectedCourse?.golf_courses?.country_id,
            timeOptions: courseDetails.timeOptions,
            startDate: courseDetails.startDate,
            isOpen: false,
            startTime: courseDetails.selectedTime,
            selectedPrice:courseDetails.selectedPrice,
            selectedGolfers: courseDetails.selectedGolfers,
            additional_charges: courseDetails.selectedcharges,
            courses: null,
            total: grandTotal(),
        }
        cartitems.push(selectedCourse)
        localStorage.setItem("cartData",JSON.stringify(cartitems))
        setisCarted(true)
    }
    const removeItem = id => {
       
        var cartitems =JSON.parse(localStorage.getItem("cartData")) || [];
        cartitems = cartitems.filter((data)=> data?.course_id != selectedCourse?.golf_courses?.id )
        localStorage.setItem("cartData",JSON.stringify(cartitems))
        setisCarted(false)
      }
    // -------------------------------------------
    useEffect(() => {
        console.log(courseDetails)
        if(!isAuthenticated){
            navigation('/SearchResult');
        }
        checkItemExistInCart()
    }, [])
    function checkItemExistInCart(){
        let cartitems =JSON.parse(localStorage.getItem("cartData"));
      
        if(cartitems){
            //  checkExists = cartitems.filter((data)=> data?.golf_courses?.id === selectedCourse?.golf_courses?.id )
             const found = cartitems.some(data => data?.course_id === selectedCourse?.golf_courses?.id);
             if(found){
                setisCarted(true)
            }else{
                setisCarted(false)
            }
        }
        
    }
    const calculatePercentage = (id) => {
        
    //    var total= parseInt(courseDetails?.selectedPrice) * parseInt(courseDetails?.selectedGolfers);
          var getDetail = courseDetails.filter((details)=>details.course_id === id);
              getDetail =Object.assign({}, getDetail);
              getDetail =getDetail[0];
         const golferPrice = fnctionConvertingPriceWithCurrency(parseFloat(getDetail?.selectedPrice) ,getDetail?.currency )
          var total = golferPrice * parseInt(getDetail?.selectedGolfers);
      
        return ( total * 3 / 100 ).toFixed(2); 
      }

      const grandTotal = () => {
        let grandTotalamount = 0;
    
        courseDetails?.forEach(price => {
            const selectedPrice = fnctionConvertingPriceWithCurrency(price?.selectedPrice , price?.currency) || 0;
            const selectedGolfers = parseInt(price?.selectedGolfers) || 0;
            const percentage = parseFloat(calculatePercentage(price.course_id)) || 0;
    
            grandTotalamount += selectedPrice * selectedGolfers + percentage;
        });
        return grandTotalamount.toFixed(2);
    };
  useEffect(() => {
    
    if( courseBookingInputs !==undefined){
        setcourseDetails(courseBookingInputs)
    }else{
        navigation("/SearchResult");
    }
  }, [courseBookingInputs])


 
    const [startDate, setStartDate] = useState(new Date());
    const [phoneNumber, setPhoneNumber] = useState('');
    const [leadphoneNumber, setLeadPhoneNumber] = useState('');

    const handleCloseModel = () => {
        setModelOpen(false);
    };

    const handicaps = Array.from({ length: 10 }, (_, i) => i.toString());

    const calculateVisitorTotal=(golfers,price)=>{
        var visitorPrice = golfers * price;
        return visitorPrice.toFixed(2);
    }

    const handleOpenHoliday = (row) => {
        setModelOpen(true);
    };

    const isValidData = {full_name: "", email: ""};
    const [form, setForm] = useState({ full_name: "", email: "" });
  const checkIsValid = (fieldName, value) => {
    // Here you probably what to check this to some regex validation
    if (isValidData[fieldName] === value) {
      return true;
    }
    return false;
  }
  const CopyLeadInfos=(e)=>{
    if(e.target.checked){
        setcontactName(full_name)
        setPhoneNumber(user?.code + user?.mobile)
        setcontactNationality(lead_nationality)
    }else{
        setcontactName('')
        setPhoneNumber('')
        setcontactNationality('')
    }
  }
    return (
        <div className='listitem'>
            <div className="home-content">
                {/* <BookingForm /> */}
                
          
            </div>
        
            <div className='passangerdetails-container'>
                <h4 className='passanger-detailspg-title'>Course Enquiry</h4>
                <Grid container spacing={2} className='container-d-row paass-drow'>
                    <Grid xs={12} lg={8.5} md={7.5} sm={7.5} className='passangerdetail-headersec-con'>
                        { courseDetails?.map(details =>(
                            <div className='passangerdetail-headersec'>
                            <div className='pass-depg-imgcon'>
                                <img onError={(e) => {e.target.onerror = null;e.target.src = Noimage; }} src={details?.image_path} alt="pass-depg-img" />
                            </div>
                            <div className='pass-depg-con'>
                                <h4>{details?.course_name}</h4>
                                <p>{details?.courses?.title}</p>
                                <p className='pass-depg-head-datecountry'>{details?.location} <span>{moment(details?.created_at).format("MMM Do YYYY") }</span></p>
                                <h5 className='passdepg-head-price'><span>Price :</span> {currencyCode}  {fnctionConvertingPriceWithCurrency(details?.selectedPrice , details?.currency)}</h5>
                            </div>
                        </div>
                        ))}
                       
                        {/* // <div className='passangerdetail-headersec'>
                        //     <div className='pass-depg-imgcon'>
                        //         <img src={courseDetails?.image_path} alt="pass-depg-img" />
                        //     </div>
                        //     <div className='pass-depg-con'>
                        //         <h4>{courseDetails?.course_name}</h4>
                        //         <p>{courseDetails?.courses?.title}</p>
                        //         <p className='pass-depg-head-datecountry'>{courseDetails?.location} <span>{moment(courseDetails?.created_at).format("MMM Do YYYY") }</span></p>
                        //         <h5 className='passdepg-head-price'><span>Price :</span> ${courseDetails?.selectedPrice}</h5>
                        //     </div>
                        // </div> */}
                       
                        <div className='passdetails-enq-form'>
                            <form onSubmit={PaymentPage}>
                                
                                <div className='pass-legolf-con'>
                                    <div className='pass-legolf-subcon'>
                                        <h5>Lead Golfer</h5>
                                        <Grid container spacing={2} className='container-d-row'>
                                            <Grid xs={12} lg={3} md={5.5} sm={5.5} className='max-width-full'>
                                                <div className="form-group">
                                                    <label>Full Name</label>
                                                    <input
                                                        name="full_name"
                                                        value ={full_name}
                                                        onChange={(e) => setLeadName(e.target.value)}
                                                        placeholder="Enter your full name here"
                                                        // error={full_name === ""}
                                                        // error={!checkIsValid(full_name, form[full_name])}
                                                        helperText={full_name === "" ? 'First Name is required!' : ' '}
                                                    />


                                                    {showerror && full_name ===''?  <span className="error">First Name is required</span> :'' }
                                                    {/* {errors.fullName && <span className="error">This field is required</span>} */}
                                                </div>
                                            </Grid>
                                            <Grid xs={12} lg={3} md={5.5} sm={5.5} className='max-width-full'>
                                                <div className="form-group">
                                                    <label>Email:</label>
                                                    <input
                                                        name="email"
                                                        value ={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        placeholder="Enter your email here"
                                                        required
                                                    />
                                                    {showerror && email ==='' ?  <span className="error">Email is required</span>:'' }
                                                    {/* {errors.fullName && <span className="error">This field is required</span>} */}
                                                </div>
                                            </Grid>
                                            <Grid xs={12} lg={3} md={5.5} sm={5.5} className='max-width-full'>
                                                <div className="form-group">
                                                    <label>Nationality</label>
                                                    <select
                                                        name="lead_nationality"
                                                        // value={lead_nationality}
                                                        // value="EC"
                                                        onChange={(e) => setleadNationality(e.target.value)}
                                                    >
                                                        <option value="">Select Nationality</option>
                                                       
                                                        {nationalities.map((nation, index) => (
                                                            <option key={index} selected={nation?.country_code === user?.country_code} value={nation?.name} >{nation?.name}</option>
                                                        ))}
                                                    </select>
                                                    <div className='dropdownicon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                                                            <path d="M17.9202 8.17969H11.6902H6.08024C5.12024 8.17969 4.64024 9.33969 5.32024 10.0197L10.5002 15.1997C11.3302 16.0297 12.6802 16.0297 13.5102 15.1997L15.4802 13.2297L18.6902 10.0197C19.3602 9.33969 18.8802 8.17969 17.9202 8.17969Z" fill="#292D32" />
                                                        </svg>
                                                    </div>
                                                    {showerror && lead_nationality ==='' ? <span className="error">This field is required</span>:'' }
                                                </div>
                                            </Grid>
                                            <Grid xs={12} lg={3.8} md={5.5} sm={5.5} className='max-width-full'>
                                                <div className="form-group">
                                                    <label>Contact Number</label>
                                                    <PhoneInput
                                                        country={'us'} 
                                                        name="contact_no"
                                                        value={leadphoneNumber}
                                                        onChange={setLeadPhoneNumber}
                                                    />
                                                    {showerror && leadphoneNumber ==='' ?  <span className="error">This field is required</span>:'' }
                                                </div>
                                               
                                            </Grid>
                                            <Grid xs={12} lg={3} md={5.5} sm={5.5} className='max-width-full'>
                                                <div className="form-group">
                                                    <label>Golf Handicap</label>
                                                    <select
                                                        name="lead_handicap"
                                                        value={lead_handicap}
                                                        onChange={(e) => setLeadHandiCap(e.target.value)}
                                                    >  <option  value="">Select Handicap</option>
                                                        {handicaps.map((handicap, index) => (
                                                            <option key={index} value={handicap}>{handicap}</option>
                                                        ))}
                                                    </select>
                                                    <div className='dropdownicon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                                                            <path d="M17.9202 8.17969H11.6902H6.08024C5.12024 8.17969 4.64024 9.33969 5.32024 10.0197L10.5002 15.1997C11.3302 16.0297 12.6802 16.0297 13.5102 15.1997L15.4802 13.2297L18.6902 10.0197C19.3602 9.33969 18.8802 8.17969 17.9202 8.17969Z" fill="#292D32" />
                                                        </svg>
                                                    </div>
                                                    {showerror && lead_handicap ==='' ?  <span className="error">This field is required</span>:'' }
                                                </div>
                                            </Grid>
                                            <Grid xs={12} md={12} lg={12} className='max-width-full pass-allowbx'>
                                                <div className="form-group">
                                                    <div class="checkbox-wrapper-13 goho-check-df">
                                                        <input type="checkbox" onChange={(e) => CopyLeadInfos(e)} /><label for="c1-13">Same as lead detail</label>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                                
                               
                                <div className='passdetails-contact-con'>
                                    <div className='passdetails-subcon'>
                                        <h5>Contact Details</h5>
                                        <Grid container spacing={2} className='container-d-row'>
                                            <Grid xs={12} md={3.8} className='max-width-full'>
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <input
                                                     name="contact_name"
                                                     value={contact_name}
                                                    onChange={(e) => setcontactName(e.target.value)}
                                                    placeholder="Enter your name here"
                                                    />
                                                    {showerror && contact_name ===''?  <span className="error">This field is required</span> :'' }
                                                </div>
                                            </Grid>
                                            <Grid xs={12} md={3.8} className='max-width-full'>
                                                <div className="form-group">
                                                    <label>Phone Number</label>
                                                    <PhoneInput
                                                        country={'us'} 
                                                        name="contact_no"
                                                        value={phoneNumber}
                                                        onChange={setPhoneNumber}
                                                    />
                                                    {showerror && phoneNumber ===''?  <span className="error">This field is required</span> :'' }
                                                </div>
                                               
                                            </Grid>
                                            <Grid xs={12} md={3.8} className='max-width-full'>
                                                <div className="form-group">
                                                    <label>Nationality</label>
                                                    <select
                                                        name="contact_nationality"
                                                        // value={contact_nationality}
                                                        onChange={(e) => setcontactNationality(e.target.value)}
                                                    >
                                                        <option value="">Select Nationality</option>
                                                        {nationalities.map((nation, index) => (
                                                            <option key={index} value={nation?.name} selected={nation?.country_code === contact_nationality}>{nation?.name}</option>
                                                        ))}
                                                    </select>
                                                    <div className='dropdownicon'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none">
                                                            <path d="M17.9202 8.17969H11.6902H6.08024C5.12024 8.17969 4.64024 9.33969 5.32024 10.0197L10.5002 15.1997C11.3302 16.0297 12.6802 16.0297 13.5102 15.1997L15.4802 13.2297L18.6902 10.0197C19.3602 9.33969 18.8802 8.17969 17.9202 8.17969Z" fill="#292D32" />
                                                        </svg>
                                                    </div>
                                                    {showerror && contact_nationality ===''?  <span className="error">This field is required</span> :'' }
                                                </div>
                                            </Grid>
                                            <Grid xs={12} md={5} sm={5} lg={7.9} className='max-width-full'>
                                                <div className="form-group">
                                                    <label>Special Remarks</label>
                                                    <textarea
                                                        name="remark"
                                                        onChange={(e) => setRemarks(e.target.value)}
                                                        placeholder="Enter special remarks"
                                                    />
                                                   
                                                </div>
                                            </Grid>
                                            <Grid xs={12} md={6} lg={3.8} sm={6} className='max-width-full'>
                                                <div className="form-group captach-grp">
                                                    <div className='captacha-inbox'>
                                                        <label htmlFor="captachacode">Captcha Code</label>
                                                        <input
                                                            type="text"
                                                            id="captchacode"
                                                            name="captchacode"
                                                            placeholder='Captcha Code here'
                                                            onChange={(e) => setCaptcha(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='captacha-img-box'>
                                                        <div className='cap-img'>
                                                        <LoadCanvasTemplate /> 
                                                        {/* <LoadCanvasTemplateNoReload />  */}
                                                        </div>
                                                        <div className='cap-restart' style={{display: "none"}}>
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <g clip-path="url(#clip0_68_1121)">
                                                                        <path d="M12 5V1L7 6L12 11V7C15.31 7 18 9.69 18 13C18 16.31 15.31 19 12 19C8.69 19 6 16.31 6 13H4C4 17.42 7.58 21 12 21C16.42 21 20 17.42 20 13C20 8.58 16.42 5 12 5Z" fill="#598B28" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_68_1121">
                                                                            <rect width="24" height="24" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                    {/* <LoadCanvasTemplateNoReload /> */}
                                                </div>
                                            </Grid>
                                            <Grid xs={12} md={12} className='last-submitbtn-con'>
                                                <div className="form-group allow-access-box">
                                                    <div class="checkbox-wrapper-13 goho-check-df">
                                                   
                                                        <input type="checkbox" onChange={(e) => checkConformation()} /><label for="c1-13">By submitting information, I agree to Golftripz T&C &
                                                            Privacy Policy.</label>
                                                            
                                                    </div>
                                                    
                                                    <div className='email-allow-check'>
                                                    
                                                        <p>I would like to receive marketing promotions from following communication channels:</p>
                                                        {showerror && acceptSubmit !=true ?  <p className="error">This field is required</p>:'' }
                                                        <div className='email-all-df-bx'>
                                                            <div class="checkbox-wrapper-13 goho-check-df">
                                                                <input type="checkbox" onChange={(e) => IsEmailChecked()} /><label for="c1-13">Email</label>
                                                            </div>
                                                            {/* {emailBooking ===''?  <span className="error">This field is required</span> :'' } */}
                                                            <div class="checkbox-wrapper-13 goho-check-df">
                                                                <input type="checkbox" onChange={(e) => IsMessaging()} /><label for="c1-13">Online Messaging Apps</label>
                                                            </div>
                                                            {/* {mesagingApp ===''?  <span className="error">This field is required</span> :'' } */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" onClick={PaymentPage}>Submit</button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Grid>
                    <Grid xs={12} md={4} sm={4} lg={3} className='passde-sidebx-con max-width-full'>
                        <div className='passdetails-sidebox'>
                              <div className='passde-sidebx-head'>
                                <h4>Price & Summary</h4>
                              </div>
                              

                              
                              
                              {/* Runn loop  */}
                              {courseDetails?.map(details =>(
                                <>
                                   <div className='passde-sidebx-subtit'>
                                   <p>{details?.course_name}</p>
                                   <p className='subpara'>{details?.courses?.title}</p>
                                    </div>
                                   <div className='passde-side-timebx'>
                                   
                                    <div className='passde-side-clockicon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none">
                                        <path d="M12.0001 4.64844C7.22008 4.64844 3.33008 8.53844 3.33008 13.3184C3.33008 18.0984 7.22008 21.9984 12.0001 21.9984C16.7801 21.9984 20.6701 18.1084 20.6701 13.3284C20.6701 8.54844 16.7801 4.64844 12.0001 4.64844ZM12.7501 12.9984C12.7501 13.4084 12.4101 13.7484 12.0001 13.7484C11.5901 13.7484 11.2501 13.4084 11.2501 12.9984V7.99844C11.2501 7.58844 11.5901 7.24844 12.0001 7.24844C12.4101 7.24844 12.7501 7.58844 12.7501 7.99844V12.9984Z" fill="#292D32" />
                                        <path d="M14.8901 3.45H9.11014C8.71014 3.45 8.39014 3.13 8.39014 2.73C8.39014 2.33 8.71014 2 9.11014 2H14.8901C15.2901 2 15.6101 2.32 15.6101 2.72C15.6101 3.12 15.2901 3.45 14.8901 3.45Z" fill="#292D32" />
                                    </svg>
                                </div>
                                <p>Tee Slot - {moment(details?.startDate).format("MMM Do") } | {details?.startTime}</p>
                                </div>
                                <div className='passde-pricede'>
                                <div>
                                    <p className='passde-para-df'><span>Visitors</span>{details?.selectedGolfers} x {currencyCode} {fnctionConvertingPriceWithCurrency(details?.selectedPrice , details?.currency)}</p>
                                    {/* <p className='passde-total-green'>Tot. USD {Number(details?.selectedGolfers) * Number(details?.selectedPrice).toFixed(2)}</p> */}
                                    <p className='passde-total-green'>Tot. {currencyCode} {calculateVisitorTotal(details?.selectedGolfers,fnctionConvertingPriceWithCurrency(details?.selectedPrice , details?.currency))}</p>
                                </div>
                                <div>
                                    <p className='passde-para-df'><span>Convenience</span>{details?.selectedGolfers} x {currencyCode} {(fnctionConvertingPriceWithCurrency(details?.selectedPrice , details?.currency) * 3 / 100).toFixed(2)}</p>
                                    <p className='passde-total-green'>Tot. {currencyCode} {calculatePercentage(details.course_id)}</p>
                                </div>
                                {/* <div>
                                    <p className='passde-para-df'><span>Buggy</span>4 x USD 24.00</p>
                                    <p className='passde-total-green'>Tot. USD 96.00</p>
                                </div> */}
                                {details?.additional_charges.map(charge => 
                                            charge.quantity != "0" ? (<div>
                                                <p className='passde-para-df'><span>{charge.service_name}</span>{charge.quantity} x {currencyCode} {charge.cost}</p>
                                                <p className='passde-total-green'>Tot. {currencyCode} {charge.cost * charge.quantity}</p>
                                            </div>) : 
                                                ''
                                            )} 
                          </div>
                                </>
                                ))}
                              {/* end loop */}
                              <div className='passde-side-totalamt'>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M14.1665 2.91406H5.83317C3.33317 2.91406 1.6665 4.16406 1.6665 7.08073V12.9141C1.6665 15.8307 3.33317 17.0807 5.83317 17.0807H14.1665C16.6665 17.0807 18.3332 15.8307 18.3332 12.9141V7.08073C18.3332 4.16406 16.6665 2.91406 14.1665 2.91406ZM5.20817 12.0807C5.20817 12.4224 4.92484 12.7057 4.58317 12.7057C4.2415 12.7057 3.95817 12.4224 3.95817 12.0807V7.91406C3.95817 7.5724 4.2415 7.28906 4.58317 7.28906C4.92484 7.28906 5.20817 7.5724 5.20817 7.91406V12.0807ZM9.99984 12.4974C8.6165 12.4974 7.49984 11.3807 7.49984 9.9974C7.49984 8.61406 8.6165 7.4974 9.99984 7.4974C11.3832 7.4974 12.4998 8.61406 12.4998 9.9974C12.4998 11.3807 11.3832 12.4974 9.99984 12.4974ZM16.0415 12.0807C16.0415 12.4224 15.7582 12.7057 15.4165 12.7057C15.0748 12.7057 14.7915 12.4224 14.7915 12.0807V7.91406C14.7915 7.5724 15.0748 7.28906 15.4165 7.28906C15.7582 7.28906 16.0415 7.5724 16.0415 7.91406V12.0807Z" fill="#684B2A" />
                                    </svg>
                                    <p>Total Price</p>
                                </div>
                                {/* {grandTotal()} */}
                                <p className='passde-total-green'>{currencyCode} {grandTotal()}</p>
                              </div>
                              <div className='passde-side-btnbox'>
                                <div className='enq-btn'>
                                    <button>Enquire Now</button>
                                </div>
                                {/* <div className='add-cart-side'>
                                    {isCarted ?
                                    <button onClick={removeItem}>Remove From Cart</button>
                                     : 
                                    <button onClick={AddToCart}>Add To Cart</button>
                                    } 
                                    
                                      
                                </div> */}
                              </div>
                              <div className='passde-cancelpolicy'>
                                    <div className='policy-df'>
                                        <p className='color-red'>CANCELLATION POLICY</p>
                                        <p onClick={() => handleOpenHoliday()}><a href="#">View Details</a></p>
                                    </div>
                                    <p>This is a booking request. We'll confirm the booking in 24 to 48 hours.</p>
                              </div>
                              <Modal open={modelOpen} onClose={handleCloseModel}>
                                <div className='dashboard-popup-box'>
                                    <Box className="modal-box">
                                    <>
                                    <h2 className="modal-header">Cancellation Policy</h2>
                                    <button className="close-button" onClick={handleCloseModel}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </button>
                                    {courseDetails && courseDetails.length > 0 && courseDetails.map(details => (
                                    <div className="modal-content">
                                            {/* {details.course_name}: */}
                                            <div dangerouslySetInnerHTML={{ __html: details.cancellation_policy || '' }} />
                                        </div>
                                    ))}
                                        </>

                                    </Box>
                                </div>
                            </Modal>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default CourseEnquiryForm;
