import { 
    LOADING, 
    GOLFCOURSELOADING,
    NEW_VIP_BOOKING_SUCCESS, 
    NEW_VIP_BOOKING_FAIL,
    NEW_POINT_BOOKING_FAIL,
    NEW_POINT_BOOKING_SUCCESS,
    VEHICLE_AVAILABILITY_SUCCESS,
    GOLFCOURSE_AVAILABILITY_SUCCESS,
    INSTANT_PACKAGES_SUCCESS,
    INSTANT_PACKAGES_INPUT,
    INSTANTPACKAGE_BOOKING,
    GOLFHOLIDAY_AVAILABILITY_SUCCESS,
    GOLFHOLIDAY_BOOKING,
    GOLFHOLIDAY_BOOKING_NOT_LOGIN,
    VEHICLE_AVAILABILITY_FAIL,
    GOLFCOURSE_AVAILABILITY_FAIL,
    RESET_ERROR,
    GOLFCOURSE_BOOKING,
    COURSE_BOOKING_ORDERS,
    NEW_BOOKING_SUCCESS,
    NEW_BOOKING_FAIL,
    COUPON_VERIFY_SUCCESS,
    COUPON_VERIFY_FAIL,
    CREDIT_TERMS_SUCCESS,
    CREDIT_TERMS_FAIL,
    BOOKING_TYPE,
    BOOKING_INPUT,
    FOOTER_MENUS,
    CART_COUNT,
    PAYMENTHISTORY

} from "../constants/bookingConstants";
import axios from '../../CustomAxios'
import { MY_TRANSFER_AVAILABILITY_SUCCESS } from "../constants/myTransferConstants";
import { vehicleRates } from "../../helper/vehicleRates";
import { getMarkup } from "./markupActions";
import { API_URL } from '../../config/Constants'
//Get Trip Availability 
export const getTripAvailability = (data) => async(dispatch, getState) => {

    const { user, additionalStops } = getState().auth

    if(user?.role === "agent"){
        await dispatch(getMarkup(user?.id))
    }
    

    const { markupLists } = getState().markup

    const bookinData = await JSON.parse(localStorage.getItem("booking"));


    dispatch({
        type: LOADING,
        payload: true
    })


    
    await axios.get(`verifycoordinates`, data)
    .then(async response => {
     
        dispatch({
            type: LOADING,
            payload: false
        })
        const { api_type, data, getMarkups, userMarkup } = response?.data
        if(api_type === "PAL"){
            dispatch({
                type: BOOKING_TYPE,
                payload: "PAL"
            })

            if(data?.length === 0){
                dispatch({
                    type: VEHICLE_AVAILABILITY_SUCCESS,
                    payload: []
                })
            }
            else{
                let datas = await vehicleRates(response?.data?.data, bookinData, getMarkups?.additionalStop, user, userMarkup?.[0])

                dispatch({
                    type: VEHICLE_AVAILABILITY_SUCCESS,
                    payload: datas
                })
            }

            
            
        }
        else if(api_type === "mytransfers"){
            dispatch({
                type: BOOKING_TYPE,
                payload: "MYTRANSFER"
            })
            dispatch({
                type: MY_TRANSFER_AVAILABILITY_SUCCESS,
                payload: response?.data?.data
            })
        }
        else{
            dispatch({
                type: VEHICLE_AVAILABILITY_SUCCESS,
                payload: []
            })
        }
       
    })
    .catch(async error => {

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: VEHICLE_AVAILABILITY_FAIL,
            payload: error
        })

    });
}

//Get  getGolfCoures 
export const getGolfResults = (data) => async(dispatch, getState) => {

    const { user, additionalStops } = getState().auth

    if(user?.role === "agent"){
        await dispatch(getMarkup(user?.id))
    }
    

    const { markupLists } = getState().markup

    const bookinData = await JSON.parse(localStorage.getItem("booking"));
    let search = JSON.parse(localStorage.getItem("booking"));
           

    dispatch({
        type: GOLFCOURSELOADING,
        payload: true
    })
    
    axios.post(API_URL + '/golf-courses', data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(function(response) {
        dispatch({
            type: GOLFCOURSELOADING,
            payload: false
        });
    
        const responseData = response?.data?.data; // Renamed to avoid conflict
    
        dispatch({
            type: BOOKING_TYPE,
            payload: "PAL"
        });
    
        // Uncomment if you need to check data length
        // if (responseData?.length > 0) {
        dispatch({
            type: GOLFCOURSE_AVAILABILITY_SUCCESS,
            payload: responseData
        });
        // }
    
    })
    .catch(function(error) {
        // Handle error
        console.error('Error fetching golf courses:', error);
    })
    .finally(function() {
        dispatch({
            type: GOLFCOURSELOADING,
            payload: false
        });
    });
}


//Get  getGolfCoures 
export const getGolfCourseBooking = (data) => async(dispatch, getState) => {

    const { user, additionalStops } = getState().auth

    if(user?.role === "agent"){
        await dispatch(getMarkup(user?.id))
    }
    
    const { markupLists } = getState().markup
   
    dispatch({
        type: LOADING,
        payload: true
    })
    dispatch({
        type: RESET_ERROR,
        payload:null
    })  
    axios(API_URL+'/golf-course-booking',{
        method: 'POST',
        data: data,
        headers: {
         'Content-Type': 'application/json'
       }
      })
        .then(function(response) {
            dispatch({
            type: LOADING,
            payload: false
            }) 
            const data = response.data;       
            
            if(data){        
              
                dispatch({
                    type: GOLFCOURSE_BOOKING,
                    payload:data
                })              
            }
                        
          }).then(function(body) {
            // console.log(body);
          });
   
}
//instant package
export const getGolfInstantPackageList = (data,datearray) => async(dispatch, getState) => {

    const { user, additionalStops } = getState().auth
// alert(datearray)

    const { markupLists } = getState().markup

    const bookinData = await JSON.parse(localStorage.getItem("booking"));
    let search = JSON.parse(localStorage.getItem("booking"));
           

    dispatch({
        type: LOADING,
        payload: true
    })
    var input_data={
        golfers:data?.golfers,
        searchdata:data?.search,
        days:datearray
    }
    dispatch({
        type: INSTANT_PACKAGES_INPUT,
        payload: input_data
    })
    dispatch({
        type: RESET_ERROR,
        payload: null
    })
    axios(API_URL+'/instant-packages',{
        method: 'POST',
        data: data,
        headers: {
         'Content-Type': 'application/json'
       }
      })
        .then(function(response) {
           
            dispatch({
            type: LOADING,
            payload: false
            }) 
           
            const {data} = response.data;       
            
            dispatch({
                type: BOOKING_TYPE,
                payload: "PAL"
            })
           
            if(data?.length > 0){              
                dispatch({
                    type: INSTANT_PACKAGES_SUCCESS,
                    payload:data
                })              
            }
                        
          }).then(function(body) {
            // console.log(body);
          });
   
}

//Get  instantpackagebooking 
export const InstantPackageBooking = (data) => async(dispatch, getState) => {

    const { user, additionalStops } = getState().auth

    if(user?.role === "agent"){
        await dispatch(getMarkup(user?.id))
    }
    
    const { markupLists } = getState().markup
   
    dispatch({
        type: LOADING,
        payload: true
    })
    
    axios(API_URL+'/instant-package-booking',{
        method: 'POST',
        data: data,
        headers: {
         'Content-Type': 'application/json'
       }
      })
        .then(function(response) {
            dispatch({
            type: LOADING,
            payload: false
            }) 
            const data = response.data;       
            
            if(data){        
              
                dispatch({
                    type: INSTANTPACKAGE_BOOKING,
                    payload:data
                })              
            }
                        
          }).then(function(body) {
            // console.log(body);
          });
   
}
//Get Footer menus
export const getFooterMenus = () => async(dispatch,) => {
    var inputs ={"footer_menus":"all"}
  
    axios(API_URL+'/get-single-page',{
        method: 'POST',
        data: inputs,
        headers: {
         'Content-Type': 'application/json'
       }
      })
        .then(function(response) {
           
            let datas = response.data;
            let footermenus = null;
            if(datas?.length > 0){
               
                dispatch({
                    type: FOOTER_MENUS,
                    payload:datas
                })
               
            }
                        
          }).then(function(body) {
            // console.log(body);
          });
}

export const getcartCount = (countcart) => async(dispatch,) => {
    
    var cartData =  JSON.parse(localStorage.getItem("cartData")) || [];
    var cartDatalength= cartData?.length;
   
    if(cartDatalength === undefined){
        cartDatalength=0;
    }
   
    dispatch({
        type: LOADING,
        payload: true
        })
                dispatch({
                    type: CART_COUNT,
                    payload:countcart
                })
                dispatch({
                    type: LOADING,
                    payload: false
                    })       
          
}

export const courseBookingOrders = (inputData) => async(dispatch,) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    dispatch({
        type: COURSE_BOOKING_ORDERS,
        payload:inputData
    })  
    dispatch({
        type: LOADING,
        payload: false
    })
}

//Add VIP BOOKING
export const addVipBooking = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`vipbooking`, data)
    .then(async response => {

        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: NEW_VIP_BOOKING_SUCCESS,
            payload: response.data
        })
       
    })
    .catch(async error => {
       

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: NEW_VIP_BOOKING_FAIL,
            payload: error
        })

    });
}


//Add POINT TO POINT BOOKING
export const addPointBooking = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`createbooking`, data)
    .then(async response => {
    
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: NEW_POINT_BOOKING_SUCCESS,
            payload: response.data
        })
       
    })
    .catch(async error => {
       

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: NEW_POINT_BOOKING_FAIL,
            payload: error
        })

    });
}

//New Booking
export const newBooking = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`createbooking`, data)
    .then(async response => {
    
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: NEW_BOOKING_SUCCESS,
            payload: response.data.data
        })
       
    })
    .catch(async error => {
       

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: NEW_BOOKING_FAIL,
            payload: error
        })

    });
}

//Get Hourly Rate
export const getHourlyRate = (data) => async(dispatch, getState) => {

    const { user, additionalStops } = getState().auth

    const { markupLists } = getState().markup

    const bookinData = await JSON.parse(localStorage.getItem("booking"));

    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`gethourlyrate`, data)
    .then(async response => {
    
        dispatch({
            type: LOADING,
            payload: false
        })
        
        if(response?.data?.data?.length === 0){
                dispatch({
                    type: VEHICLE_AVAILABILITY_SUCCESS,
                    payload: []
                })
        }
        else{
            const { data, getMarkups, userMarkup } = response?.data
            let datas = await vehicleRates(data, bookinData, getMarkups?.additionalStop, user, userMarkup?.[0])

            dispatch({
                type: VEHICLE_AVAILABILITY_SUCCESS,
                payload: datas
            })
        }
       
    })
    .catch(async error => {
       

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: VEHICLE_AVAILABILITY_FAIL,
            payload: error
        })

    });
}

export const verifyCoupon = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`coupon-verify`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })
        
        dispatch({
            type: COUPON_VERIFY_SUCCESS,
            payload: response.data.data
        })
       
    })
    .catch(async error => {
       

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: COUPON_VERIFY_FAIL,
            payload: error?.message
        })

    });
}


//Get User Credit Terms
export const getUserCreditTerms = (data) => async(dispatch) => {
    dispatch({
        type: LOADING,
        payload: true
    })
    
    await axios.post(`creditterms`, data)
    .then(async response => {
        dispatch({
            type: LOADING,
            payload: false
        })

        if(response.data.data?.length > 0){
            dispatch({
                type: CREDIT_TERMS_SUCCESS,
                payload: response.data.data[0]
            })
        }
        
       
       
    })
    .catch(async error => {
       

        dispatch({
            type: LOADING,
            payload: false
        })

        dispatch({
            type: CREDIT_TERMS_FAIL,
            payload: error?.message
        })

    });
}

//check airport
export const checkAirportType = (data) => async(dispatch) => {
    
    await axios.post(`airport/check`, data)
    .then(async response => {
        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'airportType',
                value: response.data.message
            }
        })
       
    })
    .catch(async error => {

    });
}

//Get  getGolfHoliday 
export const getGolfHolidayResults = (data) => async(dispatch, getState) => {

    const { user } = getState().auth

    if(user?.role === "agent"){
        await dispatch(getMarkup(user?.id))
    }

    dispatch({
        type: LOADING,
        payload: true
    })
    
    axios(API_URL+'/golf-holidays',{
        method: 'POST',
        data: data,
        headers: {
         'Content-Type': 'application/json'
       }
      })
        .then(function(response) {
            dispatch({
            type: LOADING,
            payload: false
            }) 
            const {data} = response.data;       
            dispatch({
                type: BOOKING_TYPE,
                payload: "PAL"
            })
           
            // if(data?.length > 0){              
                dispatch({
                    type: GOLFHOLIDAY_AVAILABILITY_SUCCESS,
                    payload:data
                })              
            // }
                        
          }).then(function(body) {
            // console.log(body);
          });
   
}

//Book Golf Holiday
export const getHolidayBooking = (data) => async(dispatch, getState) => {

    const { user } = getState().auth

    if(user?.role === "agent"){
        await dispatch(getMarkup(user?.id))
    }
    
    dispatch({
        type: LOADING,
        payload: true
    })
    dispatch({
        type: GOLFHOLIDAY_BOOKING,
        payload:''
    })  
    axios(API_URL+'/golf-holiday-booking',{
        method: 'POST',
        data: data,
        headers: {
         'Content-Type': 'application/json'
       }
      })
        .then(function(response) {
            dispatch({
            type: LOADING,
            payload: false
            }) 
            const data = response.data;       
            
            if(data){        
              
                dispatch({
                    type: GOLFHOLIDAY_BOOKING,
                    payload:data
                })              
            }
                        
          }).then(function(body) {
            // console.log(body);
          });
   
}

export const NotLoginBookingData = (data) => async(dispatch, getState) => {
    dispatch({
        type: GOLFHOLIDAY_BOOKING_NOT_LOGIN,
        payload:data
    })              
}

export const getPaymenyHistory = (data) => async(dispatch, getState) => {

    const { user } = getState().auth

    if(user?.role === "agent"){
        await dispatch(getMarkup(user?.id))
    }
    
    dispatch({
        type: LOADING,
        payload: true
    })
    axios(API_URL+'/customer-payment-history',{
        method: 'POST',
        data: data,
        headers: {
         'Content-Type': 'application/json'
       }
      })
        .then(function(response) {
            dispatch({
            type: LOADING,
            payload: false
            }) 
            const data = response.data;       
            
            if(data){        
              
                dispatch({
                    type: PAYMENTHISTORY,
                    payload:data.data
                })              
            }
                        
          }).then(function(body) {
            // console.log(body);
          });
   
}