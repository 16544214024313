import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Layout from '../Layout';
import GolfHolidaysNew from './GolfHolidaysNew';
import { BOOKING_DATA } from '../../Redux/constants/bookingConstants'
import {getGolfHolidayResults } from '../../Redux/actions/bookingActions'
import {Helmet} from "react-helmet";
import { useLocation } from 'react-router-dom';
const Index = () => {
     const [details, setDetails] = useState({'title':'Golftripz holidays','meta_desc':'Golftripz holidays','meta_keyword':'holidays , golftripz'})
     const { selectedGolfHoliday,availableHolidays, holidayBookingSuccess, bookingData, holidaybookingnotlogin } = useSelector(state => state.booking);
    const location = useLocation();
    const dispatch = useDispatch();
     
     useEffect(() => {
        if (location.pathname.startsWith("/golf-holiday/")) {
            const country = location.pathname.split("/golf-holiday/")[1];
            console.log("availableHolidays in detail page" , availableHolidays);
        }
      }, [availableHolidays]);

      useEffect(()=>{
        getHoliday();
      },[])
      const getHoliday = async() =>{
        await dispatch(getGolfHolidayResults());
      }
    return (
      
        <Layout details=''>
            <Helmet>
            <title>{selectedGolfHoliday?.golf_holidays?.meta_title}</title>
            <meta name="description" content={selectedGolfHoliday?.golf_holidays?.meta_description} />
            <meta name="keywords" content={selectedGolfHoliday?.golf_holidays?.meta_keyword} />
            </Helmet>
            <GolfHolidaysNew/>
        </Layout>
    )
}

export default Index;
